import gql from 'graphql-tag';
import { EmployeeFragment } from '@/graphql/fragments/employee';

export const employeeQuery = gql`
  query employeeById($employeeId: ID!) {
    employee(id: $employeeId) {
      id
      ...EmployeeFragment
    }
  }
  ${EmployeeFragment}
`;

export const employeeByEmailQuery = gql`
  query employeeByEmail($email: String!) {
    employee(email: $email) {
      id
      ...EmployeeFragment
    }
  }
  ${EmployeeFragment}
`;
