export type DropdownOption = Option | OptionWithAvatar | OptionWithIcon | OptionWithQuery;

export type OptionWithAvatar = Option & {
  avatar?: string;
  name: string;
  subtitle?: string;
};

export type OptionWithIcon = Option & {
  icon: any;
};

export type Option = {
  value: string | null;
  id?: string | boolean;
};

export enum OptionCategory {
  'employee',
  'identity',
  'address',
  'contract',
  'salary',
  'organisation',
  'absence',
  'ice',
  'other',
}

export type OptionWithQuery = Option & {
  query: string;
  category: OptionCategory;
};

export type OptionsTree = OptionsTreeItem[];
export type OptionsTreeItem = {
  value: string;
  children?: OptionsTreeItem[];
};

export type SearchOption = DropdownOption & { searchTags?: string[] };
