import type { Employee } from '@/types/generated/graphql';

import { createEmployeeMutation } from '@/graphql/mutations/createEmployee';
import { updateEmployeeMutation } from '@/graphql/mutations/updateEmployee';
import { employeeQuery } from '@/graphql/queries/employee';
import router from '@/router';
import { useApolloMutation, useApolloQuery } from '@/utils/apolloClient';
import { defineStore } from 'pinia';
import { computed, watch } from 'vue';

export const useEmployeeStore = defineStore('employee', () => {
  const employeeResult = useApolloQuery<{ employee: Employee }>(
    () => (employeeId.value ? employeeQuery : undefined),
    () => ({ employeeId: employeeId.value }),
  );

  const updateMutationResult = useApolloMutation(
    () => updateEmployeeMutation,
    () => ({ employeeId: employeeId.value }),
  );

  const createMutationResult = useApolloMutation(() => createEmployeeMutation);

  const employee = computed<Employee | undefined>(() => employeeResult.result.value?.employee);
  const employeeId = computed(() => {
    const employeeIdParam = router.currentRoute.value.params.employeeId;
    return Array.isArray(employeeIdParam) ? employeeIdParam[0] : employeeIdParam;
  });

  const hasError = computed<boolean>(
    () =>
      !!employeeResult.error.value ||
      !!updateMutationResult.error.value ||
      !!createMutationResult.error.value,
  );

  const isLoading = computed<boolean>(() => employeeResult.isLoading.value);

  const isMutating = computed<boolean>(() => {
    return (
      !isLoading.value &&
      (updateMutationResult.isLoading.value || createMutationResult.isLoading.value)
    );
  });

  if (import.meta.env.VITE_DEBUG) {
    watch(employee, (val) => {
      if (val) {
        console.log('[EMP] Got employee', employeeResult.error.value);
      }
    });
    watch(employeeId, (val) => {
      if (val) {
        console.log('[EMP] Got employeeId', val);
      }
    });
  }

  return {
    createEmployee: createMutationResult.mutate,
    employee,
    employeeId,
    hasError,
    isLoading,
    isMutating,
    updateEmployee: updateMutationResult.mutate,
  };
});
