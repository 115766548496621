import { authGuard, useAuth0 } from '@auth0/auth0-vue';
import {
  AcademicCapIcon,
  ArchiveBoxXMarkIcon,
  BoltIcon,
  BuildingOffice2Icon,
  BuildingOfficeIcon,
  CircleStackIcon,
  ClockIcon,
  DocumentDuplicateIcon,
  DocumentTextIcon,
  FingerPrintIcon,
  ListBulletIcon,
  PhoneIcon,
  ShieldCheckIcon,
  UsersIcon,
  SquaresPlusIcon,
  ArrowDownOnSquareStackIcon,
} from '@heroicons/vue/24/outline';
import { breakpointsTailwind } from '@vueuse/core';
import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router';

// @ts-ignore
const docsRoute = {
    path: 'docs',
    name: 'docs',
    beforeEnter() {
      window.location.href = '/docs/index.html';
    },
    meta: {
      icon: AcademicCapIcon,
      adminSubPage: true,
    },
  } as RouteRecordRaw;

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: () => import('../pages/DashboardPage.vue'),
      beforeEnter: async (_to, _from, next) => {
        const auth0 = useAuth0();
        await auth0.checkSession();
        next();
      },
    },
    {
      path: '/clients',
      name: 'clients',
      component: () => import('../pages/ClientsPage.vue'),
      meta: {
        icon: SquaresPlusIcon,
        searchTags: ['projects', 'clients'],
        mainNavigation: false,
      },
    },
    {
      path: '/clients/:clientId',
      name: 'projects',
      component: () => import('../pages/ProjectsPage.vue'),
      meta: {
        icon: SquaresPlusIcon,
        mainNavigation: false,
      },
    },
    {
      path: '/employees',
      name: 'employees',
      component: () => import('../pages/EmployeesPage.vue'),
      meta: {
        icon: UsersIcon,
        searchTags: ['employees', 'users', 'colleagues'],
        mainNavigation: true,
      },
    },
    {
      path: '/lists',
      name: 'lists',
      component: () => import('../pages/ListsPage.vue'),
      meta: {
        icon: ListBulletIcon,
        searchTags: ['lists', 'reports', 'overviews'],
        mainNavigation: true,
      },
    },
    {
      path: '/employee',
      name: 'employee',
      component: () => import('../pages/DashboardPage.vue'),
      beforeEnter: async (to, _from, next) => {
        const auth0 = useAuth0();
        await auth0.checkSession();
        next();
      },
    },
    {
      path: '/employee/:employeeId',
      component: () => import('../pages/EmployeeBasePage.vue'),
      children: [
        {
          path: '',
          name: 'personalData',
          component: () => import('../pages/PersonalDataPage.vue'),
          meta: {
            icon: FingerPrintIcon,
            searchTags: [
              'personal data',
              'name',
              'email',
              'birthDate',
              'phonenumber',
              'gender',
              'sex',
              'identity',
              'passport',
            ],
            employeeSubPage: true,
          },
        },
        {
          path: 'contact-data',
          name: 'contact',
          component: () => import('../pages/ContactPage.vue'),
          meta: {
            icon: PhoneIcon,
            searchTags: [
              'contact',
              'phone',
              'email',
              'ice',
              'in case of emergency',
              'address',
              'street',
              'postalcode',
              'city',
              'country',
            ],
            employeeSubPage: true,
          },
        },
        {
          path: 'coach',
          name: 'coach',
          component: () => import('../pages/CoachPage.vue'),
          meta: {
            icon: AcademicCapIcon,
            searchTags: ['coach', 'mentor'],
            employeeSubPage: true,
          },
        },
        {
          path: 'organisation',
          name: 'organisation',
          component: () => import('../pages/OrganisationPage.vue'),
          meta: {
            icon: BuildingOfficeIcon,
            searchTags: [
              'organisation',
              'office',
              'location',
              'track',
              'advisory',
              'cost center',
              'job',
              'seniority',
              'skill track',
            ],
            employeeSubPage: true,
          },
        },
        {
          path: 'documents',
          name: 'documents',
          component: () => import('../pages/DocumentsPage.vue'),
          meta: {
            icon: DocumentDuplicateIcon,
            searchTags: [
              'documents',
              'files',
              'attachments',
              'employee contract',
              'google drive',
              'drive',
            ],
            employeeSubPage: true,
          },
        },
        {
          path: 'long-term-absence',
          name: 'absence',
          component: () => import('../pages/AbsencePage.vue'),
          meta: {
            icon: ArchiveBoxXMarkIcon,
            searchTags: ['absence', 'actual fte', 'long term absence'],
            employeeSubPage: true,
          },
        },
        {
          path: 'contract',
          name: 'contract',
          component: () => import('../pages/ContractPage.vue'),
          meta: {
            icon: DocumentTextIcon,
            searchTags: [
              'contract',
              'end date',
              'start date',
              'contract type',
              'fte',
              'employment',
              'probation',
            ],
            employeeSubPage: true,
          },
        },
        {
          path: 'salary',
          name: 'salary',
          component: () => import('../pages/SalaryPage.vue'),
          meta: {
            icon: CircleStackIcon,
            searchTags: [
              'salary',
              'salary type',
              'salary amount',
              'salary currency',
              'currency',
              'vacation',
              'payroll',
            ],
            employeeSubPage: true,
          },
        },
        {
          path: 'roles',
          name: 'roles',
          component: () => import('../pages/RolesPage.vue'),
          meta: {
            icon: ShieldCheckIcon,
            searchTags: ['roles', 'permissions', 'access'],
            employeeSubPage: true,
          },
        },
        {
          path: 'scope',
          name: 'scope',
          component: () => import('../pages/ScopePage.vue'),
          meta: {
            icon: BuildingOffice2Icon,
            searchTags: ['scope', 'organisation scope', 'permissions'],
            employeeSubPage: true,
          },
        },
        {
          path: 'changelog',
          name: 'changelog',
          component: () => import('../pages/ChangelogPage.vue'),
          meta: {
            icon: ClockIcon,
            searchTags: ['changelog', 'history', 'field events', 'log'],
            employeeSubPage: true,
          },
        },
      ],
    },
    {
      path: '/admin',
      name: 'admin',
      redirect: { name: 'global-audit-trail' },
      children: [
        {
          path: 'global-audit-trail',
          name: 'global-audit-trail',
          component: () => import('../pages/GlobalAuditTrail.vue'),
          meta: {
            icon: ShieldCheckIcon,
            adminSubPage: true,
          },
        },
        {
          path: 'bulk-import',
          name: 'bulk-import',
          component: () => import('../pages/BulkImport.vue'),
          meta: {
            icon: ArrowDownOnSquareStackIcon,
            adminSubPage: true,
          },
        },
        {
          path: 'triggers',
          name: 'triggers',
          component: () => import('../pages/TriggersPage.vue'),
          meta: {
            icon: BoltIcon,
            adminSubPage: true,
          },
        },
        {
          path: 'triggers/edit',
          name: 'newTrigger',
          component: () => import('../pages/TriggersPageEdit.vue'),
          children: [
            {
              path: ':triggerId',
              name: 'editTrigger',
              component: () => import('../pages/TriggersPageEdit.vue'),
            },
          ],
        },
        docsRoute,
      ]
    },
    {
      path: '/styleguide',
      name: 'styleguide',
      component: () => import('../pages/Styleguide.vue'),
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: () => import('../pages/PageNotFound.vue'),
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  //Let the homepages be accessible without authentication
  if (to.name === 'dashboard' || to.name === 'styleguide') {
    return next();
  }

  await authGuard(to);
  next()
});

router.afterEach((to, from) => {
  const routes = router.getRoutes();
  const fromSidebarIndex = routes.findIndex((route) => {
    return route.name === from.name && route.meta.employeeSubPage;
  });
  const toSidebarIndex = routes.findIndex((route) => {
    return route.meta.employeeSubPage && route.name === to.name;
  });

  // Do not transition if:
  // The to- or from-page is a page that is not in the sidebar
  // The device is below our sm breakpoint
  if (fromSidebarIndex === -1 || toSidebarIndex === -1 || screen.width < breakpointsTailwind.sm) {
    return '';
  }

  to.meta.transition = toSidebarIndex < fromSidebarIndex ? 'page-down' : 'page-up';
});

export default router;
