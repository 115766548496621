import type { Permissions } from '@/types/generated/graphql';

import { useApolloQuery } from '@/utils/apolloClient';
import { defineStore } from 'pinia';
import { computed, watch } from 'vue';
import { ownPermissionsQuery } from '@/graphql/queries/ownPermissions';
import { useOwnEmployeeStore } from './ownEmployee';

export const useOwnPermissionsStore = defineStore('ownpermissions', () => {
  const ownEmployeeStore = useOwnEmployeeStore();
  const ownEmployeeId = computed(() => ownEmployeeStore.ownEmployee?.id);

  const ownPermissionsResult = useApolloQuery<{ ownPermissions: Permissions }>(
    () => (ownEmployeeId.value ? ownPermissionsQuery() : undefined),
    {
      ownEmployeeId: ownEmployeeId,
    },
  );

  const ownPermissions = computed(() => ownPermissionsResult.result.value?.ownPermissions.employee);

  const hasError = computed<boolean>(() => !!ownPermissionsResult.error.value);

  const isLoading = computed<boolean>(() => ownPermissionsResult.isLoading.value);

  if (import.meta.env.VITE_DEBUG) {
    watch(ownPermissions, (val) => {
      if (val) {
        console.log('[OPERM] Got ownPermissions', ownPermissionsResult.error.value);
      }
    });
  }

  return {
    ownPermissions,
    hasError,
    isLoading,
  };
});
