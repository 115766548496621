<script setup lang="ts">
import Dots from '@/components/homepage/dots.vue';

const linkClassNames = 'inline-flex text-white underline-offset-4 ring-offset-white hover:text-white hover:underline hover:opacity-70 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2 transition-opacity transition-colors';
</script>

<template>
  <footer class="bg-black px-5 text-white">
    <div class="max-w-screen-4xl mx-auto grid py-5">
      <!-- Dots -->
      <dots />

      <div class="flex flex-col py-24 md:flex-row">
        <div class="w-full md:w-1/2">
          <a href="https://eidra.com" title="Eidra home page">
            <img
              alt="Eidra logo"
              class="w-32 md:w-64"
              style="color: transparent; aspect-ratio: 3.115107913669065"
              src="@/assets/eidra-logo-white.svg"
            />
          </a>
        </div>

        <div class="w-full pt-16 md:w-1/2 md:pt-0">
          <ul class="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <div class="mb-10">
              <li>
                <strong class="mb-5 block">About</strong>
                <ul class="grid gap-y-1">
                  <li>
                    <a href="https://eidra.com" title="Eidra home page" :class="linkClassNames">
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://eidra.com/about"
                      title="About us"
                      :class="linkClassNames"
                    >
                      About us
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://eidra.com/companies"
                      title="Our companies"
                      :class="linkClassNames"
                    >
                      Our companies
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://eidra.com/contact"
                      title="Contact"
                      :class="linkClassNames"
                    >
                      Contact
                    </a>
                  </li>
                </ul>
              </li>
            </div>

            <div class="mb-10">
              <li>
                <strong class="mb-5 block">Contact</strong>
                <ul class="grid gap-y-1">
                  <li>
                    <a
                      href="mailto:new@eidra.com"
                      title="new@eidra.com"
                      :class="linkClassNames"
                    >
                      new@eidra.com
                    </a>
                  </li>
                  <li>
                    <a
                      href="mailto:career@eidra.com"
                      title="career@eidra.com"
                      :class="linkClassNames"
                    >
                      career@eidra.com
                    </a>
                  </li>
                  <li>
                    <a
                      href="mailto:press@eidra.com"
                      title="press@eidra.com"
                      :class="linkClassNames"
                    >
                      press@eidra.com
                    </a>
                  </li>
                </ul>
              </li>
            </div>

            <div class="mb-10">
              <li>
                <ul class="grid gap-y-1">
                  <li>
                    <a
                      href="https://eidra.com/cookies"
                      title="Cookie Policy"
                      :class="linkClassNames"
                    >
                      Cookie Policy
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://eidra.com/privacy"
                      title="Privacy Policy"
                      :class="linkClassNames"
                    >
                      Privacy Policy
                    </a>
                  </li>
                </ul>
              </li>
            </div>

            <div class="mb-10">
              <li>
                <strong class="block">Eidra © {{ new Date().getFullYear() }}</strong>
              </li>
            </div>
          </ul>
        </div>
      </div>

      <!-- Dots -->
      <dots />
    </div>
  </footer>
</template>
