<template>
  <nav
    v-if="isEmployeePage && store.employee"
    class="sticky left-0 top-0 z-10 w-full overflow-y-auto border-b border-subtle bg-background px-4 text-on-background lg:h-screen lg:w-72 lg:border-b-0 lg:border-r lg:py-6 xl:w-80"
    aria-label="secondary navigation"
  >
    <ul class="flex w-full items-center gap-1 py-2 lg:flex-col lg:items-start lg:py-0">
      <li class="side-navigation-item lg:mb-2 lg:w-full">
        <Avatar
          class="lg:h-16 lg:w-16 lg:text-heading-3"
          :name="store.employee.fullName ?? 'New Employee'"
          :email="store.employee.id"
          :profile-picture="store.employee.internalAvatar"
          :link="true"
          :current="router.currentRoute.value.name === 'Account Data'"
          show-name="desktop"
          :subtitle="
            store.employee.organisationData.legalEntity
              ? t(`legalEntityPrettyName.${store.employee.organisationData.legalEntity}`)
              : undefined
          "
        />
      </li>
      <SideNavigationItem
        v-for="item in navigationItems"
        :key="item.name"
        :route="item"
        :current="router.currentRoute.value.name === item.name"
      />
    </ul>
  </nav>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router';
import { useEmployeeStore } from '@/stores/employee';
import { getStaticRoutes } from '@/utils';

import Avatar from '@/components/shared/Avatar.vue';
import SideNavigationItem from '@/components/SideNavigationItem.vue';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';

const { t } = useI18n();
const router = useRouter();
const store = useEmployeeStore();
const navigationItems = getStaticRoutes().filter((r) => r.meta.employeeSubPage);

const isEmployeePage = computed(() => {
  return router.currentRoute.value.fullPath.indexOf('/employee/') === 0;
});
</script>
