<template>
  <Homepage v-if="!authenticationStore.isAuthenticated?.value" />
  <BaseLayout v-else>
    <ServiceUnavailableError v-if="ownEmpStore.isServiceUnavailable" />
    <InternalServerError v-else-if="ownEmpStore.hasError" />
    <div v-else-if="isLoading" class="flex h-full items-center">
      <Loader />
    </div>
    <RouterView v-else-if="authenticationStore.isAuthenticated" />
  </BaseLayout>
</template>

<script setup lang="ts">
import type { AnySchema } from 'yup';
import { addMethod, MixedSchema, setLocale } from 'yup';

import { computed } from 'vue';
import { RouterView } from 'vue-router';
import { useAuth0 } from '@auth0/auth0-vue';

import BaseLayout from '@/components/layouts/BaseLayout.vue';
import Loader from '@/components/shared/Loader.vue';
import ServiceUnavailableError from '@/pages/ServiceUnavailableError.vue';
import InternalServerError from '@/pages/InternalServerError.vue';
import { useOwnEmployeeStore } from './stores/ownEmployee';
import Homepage from '@/pages/Homepage.vue';

const authenticationStore = useAuth0();
const ownEmpStore = useOwnEmployeeStore();

setLocale({
  mixed: {
    required: 'This field is required',
  },
  number: {
    min: 'This field must be at least ${min}',
    max: 'This field must be at most ${max}',
  },
  string: {
    email: 'This is not a valid e-mail address',
  },
});

// Adds yup support for multiple types
addMethod(MixedSchema, 'oneOfSchemas', function (schemas: AnySchema[]) {
  return this.test(
    'one-of-schemas',
    'Not all items in ${path} match one of the allowed schemas',
    (item) => schemas.some((schema) => schema.isValidSync(item, { strict: true })),
  );
});

const isLoading = computed(() => {
  return authenticationStore.isLoading.value || ownEmpStore.isLoading;
});
</script>
