<template>
  <nav
    v-if="isAdminPage"
    class="sticky left-0 top-0 z-10 w-full overflow-y-auto border-b border-subtle bg-background px-4 text-on-background lg:h-screen lg:w-72 lg:border-b-0 lg:border-r lg:py-6 xl:w-80"
    aria-label="secondary navigation"
  >
    <ul class="flex w-full items-center gap-1 py-2 lg:flex-col lg:items-start lg:py-0">
      <SideNavigationItem
        v-for="item in navigationItems"
        :key="item.name"
        :route="item"
        :current="router.currentRoute.value.name === item.name"
      />
    </ul>
  </nav>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router';
import { useEmployeeStore } from '@/stores/employee';
import { getStaticRoutes } from '@/utils';

import SideNavigationItem from '@/components/SideNavigationItem.vue';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useOwnPermissionsStore } from '@/stores/ownPermissions';
import { Scopes } from '@/types/generated/graphql';
import { useOwnEmployeeStore } from '@/stores/ownEmployee';

const { t } = useI18n();
const router = useRouter();
const store = useEmployeeStore();
const ownEmployeeStore = useOwnEmployeeStore();
const { ownPermissions } = storeToRefs(useOwnPermissionsStore());

const navigationItems = computed(() => {
  const showAuditTrail = ownPermissions.value?.roles?.write && ownPermissions.value?.scopes?.write;
  const hasAdminRights = ownPermissions.value?._id?.write;
  const hasGlobalAdminRights = hasAdminRights && ownEmployeeStore.ownEmployee?.scopes?.includes(Scopes.Eidra);

  return getStaticRoutes().filter((r) => {
    if (!r.meta?.adminSubPage) {
      return false;
    }

    if (r.name === 'global-audit-trail' && showAuditTrail) {
      return true;
    }

    if (r.name === 'triggers' && hasAdminRights) {
      return true;
    }

    if (r.name === 'bulk-import' && hasGlobalAdminRights) {
      return true;
    }

    if (r.name === 'docs') {
      return true;
    }


  });
});

const isAdminPage = computed(() => {
  return router.currentRoute.value.fullPath.indexOf('/admin/') === 0;
});
</script>
