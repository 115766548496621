import type { Employee } from '@/types/generated/graphql';

import { employeesQuery } from '@/graphql/queries/employees';
import { useApolloQuery } from '@/utils/apolloClient';
import { defineStore } from 'pinia';
import { computed, ref, watch, type Ref } from 'vue';

export const useEmployeesStore = defineStore('employees', () => {
  const showFormerEmployees: Ref<boolean> = ref(false);

  const employeesResult = useApolloQuery<{ employees: Employee[] }>(
    () =>
      employeesQuery([
        'internalAvatar',
        'fullName',
        'employmentStatus',
        'email',
        'roles',
        'phonePersonal',
        'organisationData { legalEntity organisation }',
        'slack { id }',
      ]),
    {
      showFormerEmployees: computed<boolean>(() => showFormerEmployees.value ?? false),
    },
  );

  const employees = computed<Employee[]>(() => employeesResult.result.value?.employees || []);

  const hasError = computed<boolean>(() => !!employeesResult.error.value);

  const isLoading = computed<boolean>(() => employeesResult.isLoading.value);

  if (import.meta.env.VITE_DEBUG) {
    watch([employees, isLoading], ([val, l]) => {
      if (!l) {
        console.log('[EMPS] Got employees', val.length, employeesResult.error.value);
      }
    });
  }

  return {
    employees,
    hasError,
    isLoading,
    showFormerEmployees,
  };
});
