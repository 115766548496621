{
  "legalEntityPrettyName": {
    "aboveAgencyAb": "Above",
    "alexanderReklamebyraAs": "Alexander Reklamebyrå",
    "eidraBeneluxBv": "Eidra Benelux",
    "eidraConsultingGroupAb": "Eidra Consulting Group AB",
    "eidraConsultingGroupAs": "Eidra Consulting Group AS",
    "eidraConsultingNl": "Eidra NL Consulting",
    "eidraNoGrowthPlatform": "Eidra NO Growth Platform",
    "eidraSeGrowthPlatform": "Eidra SE Growth Platform",
    "arielGrowthAgencyAb": "Ariel",
    "blckCommunication": "BLCK",
    "conversionistaAb": "Conversionista",
    "conversionistaAs": "Conversionista",
    "conversionistaNl": "Conversionista NL",
    "cupoleAb": "Cupole",
    "curamandoAb": "Curamando",
    "curamandoAs": "Curamando",
    "curiousMind": "Curious Mind",
    "fabrique": "Fabrique",
    "goods": "Goods",
    "heydays": "Heydays",
    "keybrokerAb": "Keybroker",
    "khCommsAb": "Kurppa Hosk Communications",
    "kreationsbyran": "Kreationsbryan",
    "kurppaHoskStudioAb": "Kurppa Hosk Studio",
    "losCoAs": "Los & Co",
    "losCoDrammenAs": "Los & Co Drammen",
    "missionAnew": "Mission Anew",
    "namelessTodayAb": "Nameless.today",
    "kurppaHoskAs": "Kurppa Hosk AS",
    "oceansAs": "Oceans",
    "produktionStockholmFilmAb": "Produktion i Stockholm Film AB (prev. Blck)",
    "punchkick": "Punchkick",
    "q42": "Q42",
    "splendRekryteringAb": "Splend Rekrytering AB (prev. Curious Mind)",
    "tbaAs": "TBA",
    "umainAb": "Umain",
    "umainAs": "Umain",
    "umainX": "Umain"
  },
  "dropdownFields": {
    "currency": {
      "DKK": "DKK",
      "EUR": "EUR",
      "NOK": "NOK",
      "SEK": "SEK",
      "USD": "USD"
    },
    "iso": {
      "DK": "DK",
      "NO": "NO",
      "NL": "NL",
      "SE": "SE",
      "US": "US"
    },
    "organisationData": {
      "costCenter": {
        "c1000": "C1000",
        "c1050": "C1050",
        "c1100": "C1100",
        "c1200": "C1200",
        "c1210": "C1210",
        "c1220": "C1220",
        "c1300": "C1300",
        "c1400": "C1400",
        "c1500": "C1500",
        "c1600": "C1600",
        "c2000": "C2000",
        "c2010": "C2010",
        "c2020": "C2020",
        "c2050": "C2050",
        "c3000": "C3000",
        "c3010": "C3010",
        "c3020": "C3020",
        "c3100": "C3100",
        "c4000": "C4000",
        "c4010": "C4010",
        "c4020": "C4020",
        "c4100": "C4100",
        "c4200": "C4200",
        "c4300": "C4300",
        "c4310": "C4310",
        "c5000": "C5000",
        "c5010": "C5010",
        "c5020": "C5020",
        "c5100": "C5100",
        "c5200": "C5200",
        "c5210": "c5210",
        "c5300": "C5300",
        "c5310": "C5310",
        "c6000": "C6000",
        "c6010": "C6010",
        "c6020": "C6020",
        "c6100": "C6100",
        "c6200": "C6200",
        "c7000": "C7000",
        "c7010": "C7010",
        "c7020": "C7020",
        "c7100": "C7100",
        "c7200": "C7200",
        "c7300": "C7300",
        "c8000": "C8000",
        "c8010": "C8010",
        "c8020": "C8020",
        "c9000": "C9000",
        "c9010": "C9010"
      },
      "legalEntity": {
        "aboveAgencyAb": "Above Agency AB",
        "alexanderReklamebyraAs": "Alexander Reklamebyrå",
        "arielGrowthAgencyAb": "Ariel Growth Agency AB",
        "blckCommunication": "BLCK Communication",
        "conversionistaAb": "Conversionista AB",
        "conversionistaAs": "Conversionista AS",
        "conversionistaNl": "Conversionista NL",
        "cupoleAb": "Cupole AB",
        "curamandoAb": "Curamando AB",
        "curamandoAs": "Curamando AS",
        "curiousMind": "Curious Mind",
        "eidraBeneluxBv": "Eidra Benelux BV",
        "eidraConsultingGroupAb": "Eidra Consulting Group AB",
        "eidraConsultingGroupAs": "Eidra Consulting Group AS",
        "eidraConsultingNl": "Eidra NL Consulting",
        "eidraNoGrowthPlatform": "Eidra NO Growth Platform",
        "eidraSeGrowthPlatform": "Eidra SE Growth Platform",
        "fabrique": "Fabrique",
        "goods": "Goods",
        "heydays": "Heydays",
        "keybrokerAb": "Keybroker AB",
        "khCommsAb": "Kurppa Hosk Communications AB",
        "kreationsbyran": "Kreationsbryan",
        "kurppaHoskStudioAb": "Kurppa Hosk Studio AB",
        "losCoAs": "Los & Co",
        "losCoDrammenAs": "Los & Co Drammen",
        "missionAnew": "Mission Anew",
        "namelessTodayAb": "Nameless.today AB",
        "kurppaHoskAs": "Kurppa Hosk AS",
        "oceansAs": "Oceans",
        "produktionStockholmFilmAb": "Produktion i Stockholm Film AB (prev. Blck)",
        "punchkick": "Punchkick",
        "q42": "Q42",
        "splendRekryteringAb": "Splend Rekrytering AB (prev. Curious Mind)",
        "tbaAs": "TBA",
        "umainAb": "Umain AB",
        "umainAs": "Umain AS",
        "umainX": "Umain X"
      },
      "employedBy": {
        "aboveAgencyAb": "Above Agency AB",
        "alexanderReklamebyraAs": "Alexander Reklamebyrå",
        "arielGrowthAgencyAb": "Ariel Growth Agency AB",
        "blckCommunication": "BLCK Communication",
        "conversionistaAb": "Conversionista AB",
        "conversionistaAs": "Conversionista AS",
        "conversionistaNl": "Conversionista NL",
        "cupoleAb": "Cupole AB",
        "curamandoAb": "Curamando AB",
        "curamandoAs": "Curamando AS",
        "curiousMind": "Curious Mind",
        "eidraBeneluxBv": "Eidra Benelux BV",
        "eidraConsultingGroupAb": "Eidra Consulting Group AB",
        "eidraConsultingGroupAs": "Eidra Consulting Group AS",
        "eidraConsultingNl": "Eidra NL Consulting",
        "eidraNoGrowthPlatform": "Eidra NO Growth Platform",
        "eidraSeGrowthPlatform": "Eidra SE Growth Platform",
        "fabrique": "Fabrique",
        "goods": "Goods",
        "heydays": "Heydays",
        "keybrokerAb": "Keybroker AB",
        "khCommsAb": "Kurppa Hosk Communications AB",
        "kreationsbyran": "Kreationsbryan",
        "kurppaHoskStudioAb": "Kurppa Hosk Studio AB",
        "losCoAs": "Los & Co",
        "losCoDrammenAs": "Los & Co Drammen",
        "missionAnew": "Mission Anew",
        "namelessTodayAb": "Nameless.today AB",
        "oceansAs": "Oceans",
        "produktionStockholmFilmAb": "Produktion i Stockholm Film AB (prev. Blck)",
        "punchkick": "Punchkick",
        "q42": "Q42",
        "splendRekryteringAb": "Splend Rekrytering AB (prev. Curious Mind)",
        "tbaAs": "TBA",
        "umainAb": "Umain AB",
        "umainAs": "Umain AS",
        "umainX": "Umain X"
      },
      "organisation": {
        "above": "Above",
        "alexanderReklamebyraAs": "Alexander Reklamebyrå",
        "ariel": "Ariel",
        "blck": "BLCK",
        "blckCommunication": "BLCK",
        "conversionista": "Conversionista",
        "conversionistaCom": "Conversionista",
        "conversionistaNl": "Conversionista NL",
        "conversionistaNo": "Conversionista NO",
        "conversionistaSe": "Conversionista SE",
        "cupole": "Cupole",
        "cupoleInc": "Cupole",
        "cupoleNl": "Cupole NL",
        "cupoleSE": "Cupole SE",
        "curamando": "Curamando",
        "curamandoCom": "Curamando",
        "curamandoNo": "Curamando NO",
        "curiousmind": "Curious Mind",
        "eidra": "Eidra",
        "eidraBenelux": "Eidra Benelux",
        "eidraNorway": "Eidra Norway",
        "eidraConsultingNl": "Eidra NL Consulting",
        "fabrique": "Fabrique",
        "goods": "Goods",
        "heydays": "Heydays",
        "keybroker": "Keybroker",
        "kreationsbyra": "Kreationsbryan",
        "kreationsbyran": "Kreationsbryan",
        "kurppaHoskStudio": "Kurppa Hosk Studio",
        "kurppahosk": "Kurppa Hosk",
        "kurppahoskOslo": "Kurppa Hosk Oslo",
        "kurppahoskComms": "Kurppa Hosk Communications",
        "losCoAs": "Los & Co",
        "losCoDrammenAs": "Los & Co Drammen",
        "missionAnew": "Mission Anew",
        "missionanew": "Mission Anew",
        "nameless": "Nameless.today",
        "namelessToday": "Nameless.today",
        "kurppaHoskAs": "Kurppa Hosk AS",
        "oceansAs": "Oceans",
        "punchkick": "Punchkick",
        "q42": "Q42",
        "sthlmfilm": "SthlmFilm",
        "tbaAs": "TBA",
        "umain": "Umain",
        "umainAs": "Umain As",
        "eidraUsa": "Eidra USA",
        "kurppahoskCommsUsa": "Kurppa Hosk Communications USA",
        "kurppahoskUsa": "Kurppa Hosk USA",
        "curamandoUsa": "Curamando USA",
        "umainUsa": "Umain USA",
      }
    }
  }
}
