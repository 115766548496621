import type { Employee } from '@/types/generated/graphql';

import { employeeByEmailQuery } from '@/graphql/queries/employee';
import { useApolloQuery } from '@/utils/apolloClient';
import { defineStore } from 'pinia';
import { computed, watch } from 'vue';
import { useAuth0 } from '@auth0/auth0-vue';
import { ApolloError } from '@apollo/client/errors';

export const useOwnEmployeeStore = defineStore('ownemployee', () => {
  const auth0 = useAuth0();

  const ownEmployeeResult = useApolloQuery<{ employee: Employee }>(
    () => (auth0.user.value?.email ? employeeByEmailQuery : undefined),
    () => ({ email: auth0.user.value?.email }),
  );

  const ownEmployee = computed<Employee | undefined>(
    () => ownEmployeeResult.result.value?.employee,
  );

  const hasError = computed<boolean>(() => !!ownEmployeeResult.error.value);

  const isLoading = computed<boolean>(() => ownEmployeeResult.isLoading.value);

  const isServiceUnavailable = computed(() => {
    const err = ownEmployeeResult.error.value;
    return err instanceof ApolloError && err.message === 'Failed to fetch';
  });

  if (import.meta.env.VITE_DEBUG) {
    watch(ownEmployee, (val) => {
      if (val) {
        console.log('[OWN] Got ownEmployee', ownEmployeeResult.error.value);
      }
    });
  }

  return {
    hasError,
    isLoading,
    ownEmployee,
    isServiceUnavailable,
  };
});
