<template>
  <div
    class="flex h-[calc(100vh-80px)] w-screen items-center justify-center p-3 font-[Arial] text-[18px] md:p-0"
    :style="{
      backgroundImage: `url(${bgImage})`,
      backgroundSize: 'cover',
    }"
  >
    <div class="flex flex-col items-center">
      <img src="@/assets/eidra-logo-black.svg" class="mb-10 block w-32" alt="Eidra logo" />
      <div class="max-w-[450px] rounded-xl bg-white px-8 py-10 text-center text-black">
        <Typography is="h3" variant="heading-3" weight="bold">
          Sign in to your account
        </Typography>
        <Typography is="p" variant="body" class="mt-1">
          To use Happy People, <br/> you need to sign in with your Eidra account
        </Typography>
        <button class="text-sm mx-auto flex flex-row rounded-md bg-primary mt-8 px-4 py-2.5 text-white" @click="login">
          Login with Eidra
          <RocketLaunchIcon class="ml-3 w-5 translate-y-0.5" />
        </button>
      </div>
    </div>
  </div>

  <div class="flex justify-center bg-[rgb(240,233,227)] p-3 text-center text-black md:p-0">
    <section class="my-20 max-w-[700px]">
      <Typography is="h2" variant="heading-2" weight="medium">
        The home of People, Projects & Planning
      </Typography>
      <Typography is="p" variant="body" class="mt-10">
        This is Happy People, the HRM tool developed for Eidra employees.
        <br class="hidden md:block" />
        Designed with care and insight, it empowers employees to effortlessly manage their data,
        enabling P&C to focus on driving growth and unlocking potential through actionable insights.
      </Typography>
      <Typography is="p" variant="body" class="mt-2">
        With Happy People, experienced professionals can focus on what they do best while enjoying a
        streamlined and stress-free experience.
      </Typography>
      <Typography is="p" variant="body" class="mt-6">
        Join us in redefining workplace harmony with a tool that truly understands you.
      </Typography>
    </section>
  </div>

  <homepage-footer />
</template>

<script lang="ts" setup>
import { useAuth0 } from '@auth0/auth0-vue';
import { RocketLaunchIcon } from '@heroicons/vue/20/solid';
import bgImage from '@/assets/hp-bg.jpg';
import Typography from '@/components/shared/Typography.vue';
import HomepageFooter from '@/components/homepage/homepage-footer.vue';

const authenticationStore = useAuth0();

function login() {
  authenticationStore.loginWithRedirect()
}
</script>
