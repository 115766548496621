import type { Route, SearchOption } from '@/types';
import type { Client, Employee } from '@/types/generated/graphql';
import { i18n } from '@/i18n';
import { BuildingOfficeIcon } from '@heroicons/vue/20/solid';

const { t } = i18n.global;

export function mapEmployeeToOption(employee: Employee): SearchOption {
  return {
    id: `/employee/${employee.id}/`,
    value: employee.fullName!,
    avatar: employee.internalAvatar ?? undefined,
    name: employee.fullName!,
    searchTags: [employee.id],
    subtitle: employee.organisationData.legalEntity
      ? t(`legalEntityPrettyName.${employee.organisationData.legalEntity}`)
      : undefined,
  };
}

export function mapClientToOption(client: Client): SearchOption {
  return {
    id: `/clients/${client.id}/`,
    value: client.name!,
    searchTags: [client.id?.toString()],
    icon: BuildingOfficeIcon,
  };
}

export function mapRouteToOption(route: Route): SearchOption {
  const routeName = route.name?.toString();

  return {
    id: route.href,
    value: routeName ? t(`pages.${routeName}`) : route.path,
    icon: route.meta.icon,
    searchTags: route.meta.searchTags,
  };
}
