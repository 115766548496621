import { OptionCategory, type OptionWithQuery } from '@/types';
import type { FlattenedEmployee } from '@/types/flattenedEmployee';
import { capitalise } from '@/utils';
import type { LocationQueryValue } from 'vue-router';

export enum StandardListOptions {
  birthDate = 'Birthdate',
  address = 'Address',
  phoneNumber = 'Phone Numbers',
}

export function parseFieldOptions(
  fieldOptions: { id: string; value: string }[],
): OptionWithQuery[] {
  const parsedQueryFields: OptionWithQuery[] = [];
  fieldOptions.forEach((field) => {
    switch (field.value) {
      case 'employedBy':
        parsedQueryFields.push({
          id: field.value,
          value: field.value,
          query: field.value,
          category: OptionCategory.organisation,
        });
        break;
      case 'absence':
        parsedQueryFields.push(
          ...[
            'actualFtePercentage',
            'actualHours',
            'expectedReturnDate',
            'reason',
            'startDate',
          ].map((subField) => ({
            id: field.value + capitalise(subField),
            value: field.value + capitalise(subField),
            query: `absence { ${subField} }`,
            category: OptionCategory.absence,
          })),
        );
        break;
      case 'address':
        parsedQueryFields.push(
          ...['city', 'country', 'postalCode', 'street', 'number', 'addition'].map((subField) => ({
            id: field.value + capitalise(subField),
            value: field.value + capitalise(subField),
            query: `address { ${subField} }`,
            category: OptionCategory.address,
          })),
        );
        parsedQueryFields.push({
          id: 'fullAddress',
          value: 'fullAddress',
          query: 'address { city country postalCode street number addition }',
          category: OptionCategory.address,
        });
        break;
      case 'iceContacts':
        parsedQueryFields.push({
          id: field.id,
          value: field.value,
          query: `iceContacts { firstName lastName relation contacts { type value } }`,
          category: OptionCategory.ice,
        });
        break;
      case 'identity':
        parsedQueryFields.push(
          ...['officialFirstName', 'officialLastName', 'identifier', 'sex'].map((subField) => ({
            id: field.value + capitalise(subField),
            value: field.value + capitalise(subField),
            query: `identity { ${subField} }`,
            category: OptionCategory.identity,
          })),
        );
        break;
      case 'mentor':
        parsedQueryFields.push({
          id: 'mentorId',
          value: 'mentorId',
          query: `mentor { id }`,
          category: OptionCategory.employee,
        });
        parsedQueryFields.push({
          id: `mentorFullName`,
          value: `mentorFullName`,
          query: `mentor { fullName }`,
          category: OptionCategory.employee,
        });
        break;
      case 'organisationData':
        parsedQueryFields.push(
          ...[
            'organisation',
            'billable',
            'city',
            'costCenter',
            'country',
            'jobRole',
            'legalEntity',
            'seniority',
            'title',
            'track',
            'skillTrack',
          ].map((subField) => ({
            id: field.value + capitalise(subField),
            value: field.value + capitalise(subField),
            query: `organisationData { ${subField} }`,
            category: OptionCategory.organisation,
          })),
        );
        parsedQueryFields.push({
          id: 'organisationDataOrganisationSettingsFteHours',
          value: 'organisationDataOrganisationSettingsFteHours',
          query: 'organisationData { organisationSettings { fteHours } }',
          category: OptionCategory.organisation,
        });
        parsedQueryFields.push({
          id: 'organisationDataOrganisationSettingsCompanyCode',
          value: 'organisationDataOrganisationSettingsCompanyCode',
          query: 'organisationData { organisationSettings { companyCode } }',
          category: OptionCategory.organisation,
        });
        break;
      case 'salaryAndBenefits':
        parsedQueryFields.push(
          ...['actualSalary', 'basicSalary', 'vacationEntitlement'].map((subField) => ({
            id: field.value + capitalise(subField),
            value: field.value + capitalise(subField),
            query: `salaryAndBenefits { ${
              subField === 'vacationEntitlement' ? subField : `${subField} { amount currency }`
            } }`,
            category: OptionCategory.salary,
          })),
        );
        break;
      case 'employmentType':
      case 'endDate':
      case 'contractHours':
      case 'ftePercentage':
      case 'lastWorkingDay':
      case 'probationPeriod':
      case 'probationPeriodEndDate':
      case 'reasonForLeaving':
      case 'roles':
      case 'scopes':
      case 'startDate':
        parsedQueryFields.push({
          id: field.id,
          value: field.value,
          query: field.value,
          category: OptionCategory.contract,
        });
        break;
      case 'id':
      case 'employeeId':
      case 'firstName':
      case 'lastName':
      case 'fullName':
      case 'birthDate':
      case 'age':
      case 'email':
      case 'emailPersonal':
      case 'phonePersonal':
      case 'gender':
        parsedQueryFields.push({
          id: field.id,
          value: field.value,
          query: field.value,
          category: OptionCategory.employee,
        });
        break;
      case 'privacy':
      case 'internalAvatar':
      case 'slack':
        // Skip
        break;
      default:
        parsedQueryFields.push({
          id: field.id,
          value: field.value,
          query: field.value,
          category: OptionCategory.other,
        });
        break;
    }
  });

  return parsedQueryFields;
}

export const mapURLQueryToArray = <T>(
  query: LocationQueryValue | LocationQueryValue[],
  cb: (s: LocationQueryValue) => T,
): T[] => {
  if (!query) {
    return [];
  }

  if (Array.isArray(query)) {
    return query.map(cb);
  }

  return [cb(query)];
};