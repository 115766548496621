import gql from 'graphql-tag';
import { PermissionFragment } from '@/graphql/fragments/permission';

export const ownPermissionsQuery = () => gql`
  query ownpermissions($ownEmployeeId: ID) {
    ownPermissions: permissions {
      employee(id: $ownEmployeeId) {
        id {
          ...PermissionFragment
        }
        _id {
          ...PermissionFragment
        }
        scopes {
          ...PermissionFragment
        }
        roles {
          ...PermissionFragment
        }
      }
    }
  }
  ${PermissionFragment}
`;
