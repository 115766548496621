import { LegalEntity, type Employee } from '@/types/generated/graphql';
import type { DropdownOption, Option, OptionWithAvatar, OptionWithIcon } from '@/types';

import { cloneDeep } from 'lodash';
import { i18n } from '@/i18n';
import { mapURLQueryToArray } from './listQuery';
import type { LocationQueryValue } from 'vue-router';

export function hasAvatar(option: DropdownOption | undefined): option is OptionWithAvatar {
  return option !== undefined && option !== null && 'name' in option;
}

export function hasIcon(option: DropdownOption | undefined): option is OptionWithIcon {
  return option !== undefined && 'icon' in option;
}

export function parseEmployeeToOption({
  fullName,
  internalAvatar,
  id,
  organisationData,
}: Employee): DropdownOption {
  const { t } = i18n.global;

  return {
    avatar: internalAvatar ?? undefined,
    name: fullName!,
    id,
    value: fullName!,
    subtitle: organisationData.legalEntity
      ? t(`legalEntityPrettyName.${organisationData.legalEntity}`)
      : undefined,
  };
}

export function parseEnumToOptions(e: any | null): DropdownOption[] {
  if (!e) {
    console.warn('No enum provided');
    return [];
  }

  const values: unknown[] = Object.values(e).filter((value) => isNaN(Number(value)));

  return (values as string[]).map((value) => ({
    id: value,
    value,
  }));
}

export function parseEnumToReadonlyArray<T extends Record<string, string | number>>(
  e: T | null,
  addEmptyDefault?: true,
): readonly (T[keyof T] | '')[];
export function parseEnumToReadonlyArray<T extends Record<string, string | number>>(
  e: T | null,
  addEmptyDefault?: false,
): readonly T[keyof T][];
export function parseEnumToReadonlyArray<T extends Record<string, string | number>>(
  e: T | null,
  addEmptyDefault = true,
): readonly (T[keyof T] | '')[] | readonly T[keyof T][] {
  if (!e) {
    console.warn('No enum provided');
    return [];
  }

  const values = Object.keys(e).filter((value) => isNaN(Number(value))) as (keyof T)[];

  // Adds empty string to the array to allow empty selection for optional dropdown fields.
  // Use yup.string().required() in your schema for required dropdown fields
  if (addEmptyDefault) {
    return ['', ...values.map((value) => e[value])];
  }

  return values.map((value) => e[value]);
}

export function parseFlatArrayToOptions(arr: any[] | readonly any[]): DropdownOption[] {
  const a = cloneDeep(arr); // clone array to support readonly arrays
  return a.map((item: any) => {
    return { value: item } as Option;
  });
}

export function formatOptionLabel(val: any, name?: string): string {
  const { t } = i18n.global;

  if (val === undefined || val === null || val === '') {
    return t('dropdownFields.empty');
  }

  if (typeof val !== 'string') {
    val = val.toString();
  }

  if (['true', 'false'].includes(val)) {
    return t(`dropdownFields.${val}`);
  }

  return t(`dropdownFields.${name}.${val}`);
}

export const allLegalEntitiesOptions: DropdownOption[] = parseEnumToOptions(LegalEntity).sort(
  (a, b) => (a.value ?? '').localeCompare(b.value ?? ''),
);

export const mapLegalEntitiesQueryToOptions = (
  query: LocationQueryValue | LocationQueryValue[],
) => {
  return mapURLQueryToArray<Option>(query, (s: LegalEntity) => ({
    id: s,
    value: s,
  }));
};
