<template>
  <footer class="bg-black text-subtle">
    <div class="relative mx-auto max-w-screen-xl overflow-hidden px-6 py-6">
      <span class="absolute left-6 top-6 aspect-square w-2 rounded-full bg-white md:w-4" />
      <span class="absolute right-6 top-6 aspect-square w-2 rounded-full bg-white md:w-4" />
      <div class="flex flex-row justify-center gap-4 text-body-small sm:text-body">
        <a
          href="https://q42.slack.com/archives/C05TG2MTT3L"
          class="flex items-center justify-center transition-colors hover:text-on-primary"
          target="_blank"
        >
          <component :is="ChatBubbleLeftEllipsisIcon" class="mr-3 h-6 w-6" aria-hidden="true" />
          <p>{{ t('footer.slackChannel') }}</p>
        </a>
      </div>
      <span class="items center mt-2 flex justify-center text-body-small"
        >{{ t('footer.version') }}: {{ version }}</span
      >
    </div>
  </footer>
</template>

<script lang="ts" setup>
import { version } from '@/../package.json';
import { ChatBubbleLeftEllipsisIcon } from '@heroicons/vue/20/solid';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>
