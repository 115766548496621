import { useEmployeeStore } from '@/stores/employee';
import type { Employee, EmployeeInput, OrganisationDataInput } from '@/types/generated/graphql';
import type { GraphQLErrors } from '@apollo/client/errors';
import { isProxy, toRaw } from 'vue';

import { i18n } from '@/i18n';
import router from '@/router';

import { type AnyObjectSchema, object } from 'yup';
import { triggerToast } from '@/utils/toast';
import { cloneDeep } from 'lodash';

const { t } = i18n.global;

export type CreateEmployeeInput = Pick<EmployeeInput, 'startDate'> & MandatoryEmployeeFields;
export type MandatoryEmployeeFields = Required<
  Pick<EmployeeInput, 'email' | 'lastName' | 'firstName'> & {
    organisationData: Pick<OrganisationDataInput, 'legalEntity'>;
  }
>;

function logGraphqlErrors(errors: GraphQLErrors) {
  errors.forEach((error) => {
    console.error(`GraphQL: ${error.extensions.code}`, error);
  });
}

export async function updateEmployeeData(
  overrideData?: Partial<EmployeeInput> | EmployeeInput,
  validFrom?: Date,
) {
  const { employee, employeeId, updateEmployee } = useEmployeeStore();

  if (!employee) {
    return;
  }

  const data = {
    ...parseEmployeeToEmployeeInput(employee),
    ...overrideData,
  };

  const updateEmployeeRecord: Record<string, unknown> = {
    employeeId: employeeId,
    employee: data,
  };

  if (validFrom) {
    updateEmployeeRecord.validFrom = validFrom;
  }

  // If employmentStatus is present, it should not be sent to the API because it is a calculated value
  if (
    updateEmployeeRecord.employee &&
    typeof updateEmployeeRecord.employee === 'object' &&
    'employmentStatus' in updateEmployeeRecord.employee
  ) {
    delete updateEmployeeRecord.employee.employmentStatus;
  }
  const result = await updateEmployee?.(updateEmployeeRecord);

  // The new source of truth is the updated user from the API
  // It should always apply this new employee entity because it can differ from the local store when scheduled changes are saved
  const updatedEmployeeResult = result?.data.updateEmployee;
  if (updatedEmployeeResult) {
    // The data cannot be re-assigned because it is a constant, so we overwrite all of it
    Object.assign(employee, updatedEmployeeResult);
  }

  if (result?.errors?.length) {
    triggerToast(t('formToastMessageError'), 'error');
    logGraphqlErrors(result.errors);
  } else {
    triggerToast(t('formToastMessageSuccess'), 'success');
  }
}

export const createEmployee = async (createEmployeeData?: CreateEmployeeInput, redirect = true) => {
  const { createEmployee } = useEmployeeStore();

  const data = {
    ...createEmployeeData,
  };

  const result = await createEmployee?.({
    employee: data,
  });

  if (result?.errors?.length) {
    triggerToast(t('formToastMessageError'), 'error');
    logGraphqlErrors(result.errors);
  } else if (result?.data.createEmployee === null) {
    triggerToast(t('createEmployeeFormToastPermissionMessage'), 'error');
  } else {
    triggerToast(t('formToastMessageSuccess'), 'success');

    if (result && redirect) {
      await router.push({
        name: 'personalData',
        params: { employeeId: result.data.createEmployee.id },
      });
    }
  }
};

function parseEmployeeToEmployeeInput(e: Employee): EmployeeInput {
  // TODO: Use a more modular approach to exclude keys
  const excludedKeys = ['id', 'age', 'ftePercentage', 'fullName', 'internalAvatar', 'slack'];
  const employee = cloneDeep(e);

  // delete a calculated field
  delete employee.absence.actualFtePercentage;
  // @ts-expect-error when the above to is fixed, this won't be needed anymore
  delete employee.organisationData.organisationSettings;

  return Object.keys(employee)
    .filter((key) => !excludedKeys.includes(key))
    .reduce((obj, key) => {
      const inputKey = key as keyof EmployeeInput;
      const inputValue = employee[inputKey] ?? null;

      // Override mentor with mentorId if mentor is not a string
      // Example: mentor is coming from API as an object i.o. a string from user input
      if (inputKey === 'mentor' && typeof employee.mentor === 'object' && employee.mentor?._id) {
        return Object.assign(obj, {
          [inputKey]: employee.mentor._id,
        });
      }

      if (isProxy(inputValue)) {
        const rawObject = toRaw(inputValue);
        // Check all values from object and set them to null if falsy (except false and 0)
        if (typeof rawObject === 'object' && !Array.isArray(rawObject) && rawObject !== null) {
          const objectValue = setInvalidValuesToNull(rawObject as Record<string, any>);
          return Object.assign(obj, {
            [inputKey]: objectValue,
          });
        }
      }

      return Object.assign(obj, {
        [inputKey]: inputValue,
      });
    }, {});
}

export function getFloatFromInt(int?: number): number | null {
  if (typeof int === 'undefined' || int === null || isNaN(int)) {
    return null;
  }
  return parseFloat(int.toString() ?? '0');
}

export async function handleSuccessSubmit(
  overrideData?: Partial<EmployeeInput> | EmployeeInput,
  validFrom?: Date,
) {
  // Fix the possible validFrom flaws here
  const validFromIsDate = validFrom instanceof Date;
  await updateEmployeeData(overrideData, validFromIsDate ? validFrom : undefined);
}

export function handleCreateSuccessSubmit(
  createEmployeeData?: CreateEmployeeInput,
  redirect = true,
) {
  return createEmployee(createEmployeeData, redirect);
}

export function handleErrorSubmit(err: any) {
  console.error('Form could not be submitted', err);
  triggerToast(t('formToastMessageError'), 'error');
}

function setInvalidValuesToNull(inputObject: Record<string, any>): Record<string, any> {
  const resultObject: Record<string, any> = {};

  for (const key in inputObject) {
    // eslint-disable-next-line no-prototype-builtins
    if (inputObject.hasOwnProperty(key)) {
      if (inputObject[key] !== null && inputObject[key] !== undefined && inputObject[key] !== '') {
        resultObject[key] = inputObject[key];
      } else {
        resultObject[key] = null;
      }
    }
  }

  return resultObject;
}

/**
 * Recursively flattens a Yup schema into an array of dot-separated keys.
 *
 * @param schema - The Yup schema to be flattened.
 * @param [prefix=''] - Optional prefix for nested keys.
 * @returns {string[]} An array of dot-separated keys representing the flattened schema.
 */
export function schemaKeysToDotSeparatedArray(schema: AnyObjectSchema, prefix = ''): string[] {
  return Object.entries(schema.fields).flatMap(([key, field]) => {
    if (field instanceof object) {
      return schemaKeysToDotSeparatedArray(field, prefix ? `${prefix}.${key}` : key);
    }

    return prefix ? `${prefix}.${key}` : key;
  });
}

function warnForDirtyForm(e: Event) {
  e.preventDefault();
  // @ts-expect-error https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
  return (e.returnValue = '');
}

export function enableWarnForDirtyForm() {
  window.addEventListener('beforeunload', warnForDirtyForm, { capture: true });
}

export function disableWarnForDirtyForm() {
  window.removeEventListener('beforeunload', warnForDirtyForm, { capture: true });
}
