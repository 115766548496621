<template>
  <ul class="flex justify-between px-0" aria-hidden="true">
    <li
      aria-hidden="true"
      style="background-color: white"
      class="aspect-square h-2 rounded-full lg:h-4"
    ></li>
    <li
      aria-hidden="true"
      style="background-color: white"
      class="aspect-square h-2 rounded-full lg:h-4"
    ></li>
    <li
      aria-hidden="true"
      style="background-color: white"
      class="aspect-square h-2 rounded-full lg:h-4"
    ></li>
  </ul>
</template>
