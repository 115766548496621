import gql from 'graphql-tag';

export const employeesQuery = (variables: string[]) => gql`
  query employees($showFormerEmployees: Boolean, $legalEntities: [LegalEntity]) {
    employees(showFormerEmployees: $showFormerEmployees, legalEntities: $legalEntities) {
      id
      employeeId
      ${variables.join('\n')}
    }
  }
`;
