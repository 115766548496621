<template>
  <ComboBox
    v-if="!isLoading"
    label="Search"
    :search="to === 'all' ? 'omni' : true"
    :only-sr-label="true"
    :options="searchOptions"
    :placeholder="placeholder"
    @update:modelValue="(value) => value && router.push(value)"
    class="hidden lg:block"
  >
    <template v-slot:iconLeft>
      <MagnifyingGlassIcon class="h-5 w-5" aria-hidden="true" />
    </template>
    <template v-if="to === 'all' && keyShortcut" v-slot:iconRight>
      <kbd class="inline-flex items-center rounded border p-1 font-sans">
        {{ keyShortcut }}
      </kbd>
    </template>
  </ComboBox>
  <div v-else class="m-2 h-5 w-15 text-white whitespace-nowrap">
    Loading search...
  </div>
</template>

<script lang="ts" setup>
import type { SearchOption } from '@/types';

import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useEmployeeStore } from '@/stores/employee';
import { useUserStore } from '@/stores/user';
import {
  getStaticRoutes,
  getUserOS,
  mapClientToOption,
  mapEmployeeToOption,
  mapRouteToOption,
  resolveRoute,
} from '@/utils';
import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid';

import ComboBox from '@/components/shared/ComboBox.vue';
import { useEmployeesStore } from '@/stores/employees';
// import { useClientsStore } from '@/stores/clients';

type Props = {
  to?: 'page' | 'all';
  placeholder?: string;
};

const props = withDefaults(defineProps<Props>(), { to: 'all' });

const OS = getUserOS();
const keyShortcut = ref(getKeyboardShortcut());

const employeeStore = useEmployeeStore();
const esStore = useEmployeesStore();
const userStore = useUserStore();
// const cStore = useClientsStore();
const router = useRouter();

// const isLoading = computed(() => esStore.isLoading || cStore.isLoading);
const isLoading = computed(() => esStore.isLoading);

const searchOptions = computed(() => {
  const options: SearchOption[] = [];

  if (esStore.employees && props.to === 'all') {
    options.push(...esStore.employees.map(mapEmployeeToOption));
  }

  // if (cStore.clients && props.to === 'all') {
  //   options.push(
  //     ...cStore.clients.map(mapClientToOption),
  //   );
  // }

  if (props.to === 'page' || props.to === 'all') {
    options.push(
      ...getStaticRoutes()
        .filter((route) => route.meta.searchTags)
        .map((r) => resolveRoute(r, employeeStore.employee?.id ?? userStore.email))
        .map(mapRouteToOption),
    );
  }

  return options;
});

function getKeyboardShortcut(): '⌘K' | 'Ctrl + K' | undefined {
  if (OS === 'MacOS') {
    return '⌘K';
  }

  if (OS === 'Windows') {
    return 'Ctrl + K';
  }
}
</script>
